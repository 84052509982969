<template>
  <div>
    <KTCodePreview v-bind:title="'In phiếu'">
      <template v-slot:toolbar>
        <b-button
          variant="primary"
          size="sm"
          @click="printData"
          style="width: max-content"
        >
          <i class="fas fa-print"></i>
          In phiếu
        </b-button>
      </template>
      <template v-slot:preview>
        <div id="printMe" class="printMe">
          <b-row style="display: block" class="mb-10">
            <div class="text-center">
              <h2>
                <b>{{ stockSlip.typeName }}</b>
              </h2>
              <h6>
                <b>{{ stockSlip.modeName }}</b>
              </h6>
            </div>
          </b-row>
          <b-row>
            <b-col>
              <p>
                <span class="custom-lable-text">Mã phiếu</span>
                : {{ stockSlip.code }}
              </p>
            </b-col>
            <b-col>
              <p>
                <span class="custom-lable-text">Kho</span>
                : {{ stockSlip.sourceWarehouse.name }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                <span class="custom-lable-text">Ngày</span>
                : {{ formatDate(stockSlip.createdAt, 'HH:mm DD/MM/YYYY') }}
              </p>
            </b-col>
            <b-col>
              <p>
                <span class="custom-lable-text">Địa chỉ</span>
                : {{ stockSlip.sourceWarehouse.address }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                <span class="custom-lable-text">{{
                  stockSlip?.providerLabelName
                }}</span>
                : {{ stockSlip.provider?.name }}
              </p>
            </b-col>
            <b-col>
              <p>
                <span class="custom-lable-text">Người lập</span>
                : {{ stockSlip.createdByUser.fullName }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                <span class="custom-lable-text">Điện thoại</span>
                : {{ stockSlip.provider?.phoneNumber }}
              </p>
            </b-col>
            <b-col>
              <p v-if="stockSlip.billId">
                <span class="custom-lable-text">Từ hóa đơn</span>
                : {{ stockSlip.billId }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                <span class="custom-lable-text">Ghi chú</span>
                : {{ stockSlip.description }}
              </p>
            </b-col>
          </b-row>
          <b-table
            :items="listItem"
            :fields="fields"
            :bordered="true"
            :hover="true"
          >
            <template v-slot:cell(chassisNumber)="row">
              <div class="d-flex justify-content-center">
                <p
                  class="d-flex justify-content-center align-item-center mb-0 text-imei-no"
                  v-if="
                    row.item.product.type == PRODUCT_TYPE_ENUM.CHASSIS_ENGINE
                  "
                >
                  Số khung: {{ row.item.chassisNumber }} <br />
                  Số máy:
                  {{ row.item.engineNumber }}
                </p>
                <p
                  v-if="row.item.product.type == PRODUCT_TYPE_ENUM.SERIES"
                  class="text-imei-no mb-0"
                >
                  Serial: {{ row.item.serialNumber }}
                </p>
              </div>
            </template>
            <template slot="bottom-row">
              <td
                v-bind:colspan="4"
                class="text-right font-weight-bolder"
                style="font-weight: 600,color: '#181c32'"
              >
                <span>Tổng:</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumQuantity) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumUnitPrice) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumDiscountAmount) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumTotalAmount) }}</span>
              </td>
            </template>
          </b-table>
          <b-row>
            <b-col>
              <p>
                <span class="custom-lable-text"
                  >Tổng số tiền (viết bằng chữ)</span
                >
                : {{ stockSlip.textAmount }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <p class="mb-0">
                <span class="custom-lable-text">Người lập phiếu</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col>
              <p class="mb-0">
                <span class="custom-lable-text">Người giao hàng</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col>
              <p class="mb-0">
                <span class="custom-lable-text">Người nhận hàng</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col
              ><p class="mb-0">
                <span class="custom-lable-text">Thủ kho</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col
              ><p class="mb-0">
                <span class="custom-lable-text">Giám đốc</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import _ from 'lodash';
import { PRODUCT_TYPE_ENUM } from '@/utils/enum';
import { formatDate, convertNumberToText } from '../../../utils/common';

export default {
  data() {
    return {
      PRODUCT_TYPE_ENUM: PRODUCT_TYPE_ENUM,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '4%',
          },
          tdClass: 'text-center',
        },
        // {
        //   key: 'barCode',
        //   label: 'Mã vạch',
        //   thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        // },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '17%' },
        },
        {
          key: 'chassisNumber',
          label: 'SK - SM / Số serial',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'unitPrice',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
      ],
      listItem: [],
      stockSlip: {
        code: '',
        typeName: '',
        modeName: '',
        createdAt: '',
        providerLabelName: '',
        description: '',
        textAmount: '',
        billId: '',
        provider: {
          id: '',
          name: '',
          phoneNumber: '',
        },
        createdByUser: {
          id: '',
          fullName: '',
        },
        sourceWarehouse: {
          id: '',
          name: '',
          address: '',
        },
      },
      sumQuantity: 0,
      sumUnitPrice: 0,
      sumDiscountAmount: 0,
      sumTotalAmount: 0,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'In phiếu kho' }]);
    this.getStockSlipById();
  },
  methods: {
    formatDate,
    printData() {
      const prtHtml = document.getElementById('printMe').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <link rel="stylesheet" href="main.css" />
            <link rel="stylesheet" media="print" href="print.css" />
            ${stylesHtml}
            <style>
              @media print {
                  html, body, {
              width:  100% !important; 
              height: 100% !important; 
              font-family: Times New Roman', Times, serif;
              -webkit-print-color-adjust: exact;
              }
              .item-print {
              width: 50% !important;
              height: 50% !important;
              margin: 30px;
              padding: 0;
              page-break-after: always;
              -webkit-print-color-adjust: exact;
              box-sizing: border-box;
              
              }
              .container {
              min-width: 50% !important;
              min-height: 50% !important;
              margin: 30px;
              padding: 0;
              box-sizing: border-box;
              -webkit-print-color-adjust: exact;
              }
            .logo {
              max-height: 45px !important;                   
            }
            .header-title {
              font-weight: bold;
              text-align: center;
              font-size: 20px !important;
            }

              }
            </style>
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>
      `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, 500);
    },
    formatMoney: function (number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    getStockSlipById: async function () {
      const stockSlipId = this.$route.query.id;
      await ApiService.get(`stockslip/print/${stockSlipId}`).then((data) => {
        const responseData = data.data.data;

        responseData.stockSlipDetail.map((element, index) => {
          const item = {
            count: index + 1,
            productCode: element.product.code,
            productName: element.product.name,
            ...element,
          };
          this.listItem.push(item);
        });
        this.stockSlip = responseData;
        this.sumQuantity = _.sumBy(this.listItem, (item) =>
          parseFloat(item.quantity),
        );
        this.sumUnitPrice = _.sumBy(this.listItem, (item) =>
          parseFloat(item.unitPrice),
        );
        this.sumDiscountAmount = _.sumBy(this.listItem, (item) =>
          parseFloat(item.discountAmount),
        );
        this.sumTotalAmount = _.sumBy(this.listItem, (item) =>
          parseFloat(item.totalAmount),
        );

        this.stockSlip.textAmount = convertNumberToText(this.sumTotalAmount);
      });
      this.printData();
    },
  },
};
</script>

<style scoped>
.custom-lable-text {
  font-size: 13px;
  font-weight: 500 !important;
}
</style>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
